import moment from 'moment'

export default {
    name: 'Shipping',
    data: () => ({
        shiptab: 0,
        search: '',
        fridays: [],
        shipcols: [
            { text: 'Location', value: 'NameLookup' },
            { text: 'Ship Date', value: 'ShipDate' },
            //{ text: 'Visual Programs', value: 'JobTitleDisplay_WEB' },
            { text: 'Visual Programs', value: 'shiptitle' },
            { text: 'Tracking', value: 'TrackingNumber' },
            { text: 'Status', value: 'Status' }
        ],
    }),
    async mounted() {
        this.genFridays()
        this.getBrands()

        if (this.$route.params.id) {
            await this.$nextTick(() => {
                this.$store.dispatch('Shipping/fetchIndividual', { op: 'jsf', jsf: this.$route.params.id }, { root: true })
                    .then(() => {
                        this.shiptab = 2
                    })
                    .catch(err => {
                        console.log('FETCH INDIVIDUAL ERR')
                        console.log(err)
                    })
            })

        }
    },
    computed: {
        selectedfriday: { get() { return this.$store.getters['Shipping/getSelectedFriday'] }, set(val) { this.$store.commit('Shipping/SET_SELECTEDFRIDAY', val) } },
        selectedbrand: { get() { return this.$store.getters['Shipping/getSelectedBrand'] }, set(val) { this.$store.commit('Shipping/SET_SELECTEDBRAND', val) } },
        shiprecs: { get() { return this.$store.getters['Shipping/getShipRecs'] }, set(val) { this.$store.commit('Shipping/SET_SHIPRECS', val, 'weekly') } },
        brandshiprecs: { get() { return this.$store.getters['Shipping/getBrandShipRecs'] }, set(val) { this.$store.commit('Shipping/SET_SHIPRECS', val, 'brandship') } },
        individualshiprecs: { get() { return this.$store.getters['Shipping/getIndividualShipRecs'] }, set(val) { this.$store.commit('Shipping/SET_SHIPRECS', val, 'brandship') } },
        brands: { get() { return this.$store.getters['Shipping/getBrands'] }, set(val) { this.$store.commit('Shipping/SET_BRANDS', val) } }
    },
    methods: {
        tabChanged() {
            /* --- no op --- */
        },
        genFridays() {
            const fridayday = 5
            const today = moment().isoWeekday()
            let thisonefriday = ''
            let datepicked = ''
            let datepickedrange = ''

            if (today <= fridayday) {
                thisonefriday = moment().isoWeekday(fridayday)
            } else {
                thisonefriday = moment().add(1, 'weeks').isoWeekday(fridayday)
            }

            //console.log('THISONEFRIDAY')
            //console.log(thisonefriday.format('MM/DD/YYYY'))

            for (let x = 0; x < 25; x++) {
                datepicked = thisonefriday.format('MM/DD/YYYY')
                datepickedrange = thisonefriday.subtract(5, 'days').format('MM/DD/YYYY')

                this.fridays.push({ text: datepicked, value: datepickedrange + '|' + datepicked })

                /* --- reset --- */
                thisonefriday.add(5, 'days')
                thisonefriday.subtract(7, 'days')
            }
        },
        getShipInfo() {
            this.$store.dispatch('Shipping/fetchShipInfo', 'weekly', { root: true })
        },
        getBrands() {
            this.$store.dispatch('Shipping/fetchBrands', null, { root: true })
        },
        getshipinfobrand() {
            this.$store.dispatch('Shipping/fetchShipInfo', 'brandship', { root: true })
        },
        sendshipsheet(of) {
            window.open('/inc/fetch-file.php?name=' + of, '_blank')
        },
    }
}